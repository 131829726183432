
export const sortingOrder = [
  "Ресторан",
  "Кафе",
  "Кофейня",
  "Пекарня",
  "Готовая еда",
  "Фастфуд",
  "Столовая",
  "Бар",
];

export const sliceNames = {
  AppData: 'AppData',
  UserData: 'UserData'
}

